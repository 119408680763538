import awsmobile from "../aws-exports";
import { HttpApi } from "./http-api";

export namespace AnalyticsApi {
    /**
     * {@link AnalyticsApi.capture} 関数のオプション定義
     */
    export interface Options {
        /**
         * 中止シグナル
         */
        readonly signal?: AbortSignal | undefined;
    }

    export interface User {
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly groups: readonly string[];
        readonly login: boolean;
        readonly lastLoginTime: Date;
        readonly lastAccessTime: Date;
        readonly pageView: readonly number[];
    }
}

/**
 * ユーザー分析 API
 */
export const AnalyticsApi = new (class AnalyticsApi extends HttpApi {
    /**
     * ユーザー分析情報を列挙します。
     *
     * @param options オプション
     * @returns ユーザー分析情報
     */
    async listUsers(
        options?: AnalyticsApi.Options,
    ): Promise<AnalyticsApi.User[]> {
        const response = await this.request("/users", {
            headers: { Accept: "application/json; charset=UTF-8" },
            method: "GET",
            signal: options?.signal,
        });

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        if (!Array.isArray(data)) {
            throw new Error("Unknown error");
        }

        return data.map((userData) => new User(userData));
    }

    /**
     * ページ ビューを記録します。
     *
     * @param username ユーザー名 (メールアドレス)
     * @param url URL
     * @param options オプション
     */
    async recordPageView(
        username: string,
        url: string,
        options?: AnalyticsApi.Options,
    ): Promise<void> {
        const response = await this.request("/pv", {
            body: JSON.stringify({ url, username }),
            headers: { "Content-Type": "application/json; charset=UTF-8" },
            method: "POST",
            signal: options?.signal,
        });

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }
    }
})(awsmobile.serecaAnalyticsApiEndpoint);

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

class User implements AnalyticsApi.User {
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly groups: readonly string[];
    readonly login: boolean;
    readonly lastLoginTime: Date;
    readonly lastAccessTime: Date;
    readonly pageView: readonly number[];

    constructor(data: Record<string, unknown>) {
        this.id = data.id === undefined ? "" : String(data.id);
        this.name = data.name === undefined ? "" : String(data.name);
        this.email = data.email === undefined ? "" : String(data.email);
        this.groups = Array.isArray(data.groups) ? data.groups.map(String) : [];
        this.login = Boolean(data.login);
        this.lastLoginTime = new Date(String(data.lastLoginTime));
        this.lastAccessTime = new Date(String(data.lastAccessTime));
        this.pageView = Array.isArray(data.pageView)
            ? data.pageView.map(Number)
            : [];
    }
}
